import { RouteComponentProps } from '@reach/router';
import { Link } from 'gatsby';
import { FormattedMessage } from 'gatsby-plugin-intl';
import React from 'react';
import { buttons, staticStyles } from 'styles';

import { Container } from '../components/Container';

const NotFoundPage: React.FC<RouteComponentProps> = () => (
  <Container className={staticStyles.root}>
    <h1 css={staticStyles.boldTitle}>
      <FormattedMessage id="404.title" />
    </h1>
    <h2 css={staticStyles.title}>
      <FormattedMessage id="404.subtitle" />
    </h2>
    <p css={staticStyles.text}>
      <FormattedMessage id="404.content" />
    </p>
    <Link to="/" css={buttons.secondary.medium}>
      <FormattedMessage id="404.back-to-home" />
    </Link>
  </Container>
);

export default NotFoundPage;
